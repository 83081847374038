import {
  MyInfoState,
  ISetUserSnsRequestPayload,
  IUpdatePasswordPayload,
} from 'store/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMyInfo, IResponse, IToggleMarketingAgreementResponse } from '@types';

const initialState: MyInfoState = {
  myInfo: {} as IMyInfo,
  error: '',
  isNeededRefresh: false,
};

export const myInfoSlice = createSlice({
  name: 'myInfo',
  initialState,
  reducers: {
    refreshMyInfoRequest: (state, action: PayloadAction) => {
      state.myInfo = {} as IMyInfo;
      state.error = '';
    },
    getMyInfoRequest: (state, action: PayloadAction<boolean>) => {
      state.myInfo = {} as IMyInfo;
      state.error = '';
    },
    getMyInfoSuccess: (state, action: PayloadAction<IMyInfo>) => {
      state.myInfo = action.payload;
      state.isNeededRefresh = false;
      state.error = '';
    },
    getMyInfoFailure: (state, action: PayloadAction<Error | string>) => {
      state.myInfo = {} as IMyInfo;
      state.error = action.payload;
    },

    setUserBriefRequest: (state, action: PayloadAction<string>) => {
      state.error = '';
    },
    setUserBriefSuccess: (state, action: PayloadAction) => {
      state.isNeededRefresh = true;
      state.error = '';
    },
    setUserBriefFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    setUserSnsRequest: (
      state,
      action: PayloadAction<ISetUserSnsRequestPayload>,
    ) => {
      state.error = '';
    },
    setUserSnsSuccess: (state, action: PayloadAction) => {
      state.isNeededRefresh = true;
      state.error = '';
    },
    setUserSnsFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    updateNicknameRequest: (state, action: PayloadAction<string>) => {
      state.error = '';
    },
    updateNicknameSuccess: (state, action: PayloadAction<IResponse>) => {
      state.isNeededRefresh = true;
      state.error = '';
    },
    updateNicknameFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    updatePasswordRequest: (
      state,
      action: PayloadAction<IUpdatePasswordPayload>,
    ) => {
      state.error = '';
    },
    updatePasswordSuccess: (state, action: PayloadAction<IResponse>) => {
      state.error = '';
    },
    updatePasswordFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    updateThumbnailRequest: (state, action: PayloadAction<FormData>) => {
      state.error = '';
    },
    updateThumbnailSuccess: state => {
      state.error = '';
    },
    updateThumbnailFailure: (state, action: PayloadAction<Error | string>) => {
      state.error = action.payload;
    },

    removeMyInfo: state => {
      state.myInfo = {} as IMyInfo;
    },

    toggleMarketingAgreementRequest: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.error = '';
    },
    toggleMarketingAgreementSuccess: (
      state,
      action: PayloadAction<IToggleMarketingAgreementResponse>,
    ) => {
      state.myInfo.marketingAgreement = action.payload.marketingAgreement;
      state.error = '';
    },
    toggleMarketingAgreementFailure: (
      state,
      action: PayloadAction<Error | string>,
    ) => {
      state.error = action.payload;
    },
    minusNumOfNewAlarms: state => {
      if (state.myInfo.numOfNewAlarms > 0) {
        state.myInfo.numOfNewAlarms -= 1;
        state.isNeededRefresh = true;
      }
    },
    resetNumOfNewAlarms: state => {
      if (state.myInfo.numOfNewAlarms > 0) {
        state.myInfo.numOfNewAlarms = 0;
        state.isNeededRefresh = true;
      }
    },
  },
});

export const {
  refreshMyInfoRequest,
  getMyInfoRequest,
  getMyInfoSuccess,
  getMyInfoFailure,
  setUserBriefRequest,
  setUserBriefSuccess,
  setUserBriefFailure,
  setUserSnsRequest,
  setUserSnsSuccess,
  setUserSnsFailure,
  updateNicknameRequest,
  updateNicknameSuccess,
  updateNicknameFailure,
  updatePasswordRequest,
  updatePasswordSuccess,
  updatePasswordFailure,
  updateThumbnailRequest,
  updateThumbnailSuccess,
  updateThumbnailFailure,
  toggleMarketingAgreementRequest,
  toggleMarketingAgreementSuccess,
  toggleMarketingAgreementFailure,
  removeMyInfo,
  minusNumOfNewAlarms,
  resetNumOfNewAlarms,
} = myInfoSlice.actions;

export default myInfoSlice.reducer;
