import { getEnvironment } from './common';

class Logger {
  private static isInfoShow: boolean = true;

  private static isWarnShow: boolean = true;

  private static isErrorShow: boolean = true;

  static configure(
    isInfoShow: boolean,
    isWarnShow: boolean,
    isErrorShow: boolean,
  ) {
    Logger.isInfoShow = isInfoShow;
    Logger.isWarnShow = isWarnShow;
    Logger.isErrorShow = isErrorShow;
  }

  // 공통 조건 로직
  private static isLoggingEnabled() {
    return getEnvironment() !== 'production';
  }

  static info(headMessage: string, message: string, args: Record<string, any>) {
    if (Logger.isInfoShow && Logger.isLoggingEnabled()) {
      console.log(`[${headMessage}] ${message}`, args);
    }
  }

  static warn(headMessage: string, message: string, args: Record<string, any>) {
    if (Logger.isWarnShow && Logger.isLoggingEnabled()) {
      console.warn(`[${headMessage}] ${message}`, args);
    }
  }

  static error(
    headMessage: string,
    message: string,
    args: Record<string, any>,
  ) {
    if (Logger.isErrorShow && Logger.isLoggingEnabled()) {
      console.error(`[${headMessage}] ${message}`, args);
    }
  }
}

export default Logger;
